blockquote,
dd,
dl,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
pre {
  margin: 0;
}

// taro 组件引入导致全局不可选，临时设置，等到移动端应用和桌面应用隔离后可以再删除
body, html {
  user-select: auto!important;
}

// taro 组件引入全局样式 height: 1.4rem 导致平台输入框样式异常
input {
  height: auto;
}

body {
  // taro 组件引入导致 button line-height 被重置，导致按钮高度不对影响布局
  line-height: 1.5!important;

  .taro-img__mode-aspectfit {
    vertical-align: top;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  // taro 组件引入全局样式导致出现滚动条
  .taro-scroll-view__scroll-x {
    overflow-x: auto;
  }
  .taro-scroll-view__scroll-y {
    overflow-y: auto;
  }

  .ant-pro-sider-menu .anticon {
    font-size: 19px !important;
  }

  .ant-layout.ant-layout-has-sider {
    z-index: 1;
  }

  .ant-pro-sider-light {
    z-index: 1;
  }

  .ant-pro-basicLayout .ant-layout-header.ant-pro-header-light {
    z-index: 1 !important;
  }

  .rc-tree-select-tree {
    border: none;
  }
  .rc-tree-select-dropdown {
    border: none;
  }
  .rc-tree-select-tree
    .rc-tree-select-tree-treenode
    span.rc-tree-select-tree-switcher,
  .rc-tree-select-tree
    .rc-tree-select-tree-treenode
    span.rc-tree-select-tree-checkbox {
    background-image: none !important;
  }
  .rc-tree-select-single:not(.rc-tree-select-customize-input)
    .rc-tree-select-selector {
    padding: 0;
  }
}

// 解决代码编辑中文字体问题，但是会导致英文字体不是代码字体
// .CodeMirror pre.CodeMirror-line,
// .CodeMirror pre.CodeMirror-line-like {
//   font-family: auto !important;
// }

.full-height-form {
  height: 100%;

  & > form {
    height: 100%;
  }
}

.designable-drawer {
  .dn-aux-helpers button {
    font-size: 14px !important;
    display: flex !important;
    align-items: center !important;
    padding: 0 3px !important;
    height: 20px !important;
    color: var(--dn-aux-selector-btn-color) !important;
    background: var(--dn-aux-selector-btn-bg-color) !important;
    border-color: var(--dn-aux-selector-btn-border-color) !important;
  }

  .dn-main-panel.root {
    height: auto;
    top: 57px;
    bottom: 49px;
  }

  .dn-light {
    --dn-brand-color: var(--ads-v2-color-bg-brand);
    --dn-brand-hovering: var(--ads-v2-color-bg-brand-emphasis);
    --dn-brand-dragging: rgba(0, 0, 0, 0.26);
    --dn-brand-dropping: rgba(0, 0, 0, 0.34);
    --dn-brand-moving: rgba(0, 0, 0, 0.5);
  }
}

/* react-sortable-tree styles */
.layout-editor-rst {
  .rstcustom__expandButton {
    background: #fff
      url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCI+PGNpcmNsZSBjeD0iOSIgY3k9IjkiIHI9IjgiIGZpbGw9IiNGRkYiLz48ZyBzdHJva2U9IiM5ODk4OTgiIHN0cm9rZS13aWR0aD0iMS45IiA+PHBhdGggZD0iTTQuNSA5aDkiLz48cGF0aCBkPSJNOSA0LjV2OSIvPjwvZz4KPC9zdmc+")
      no-repeat center;
  }
  .rstcustom__collapseButton {
    background: #fff
      url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCI+PGNpcmNsZSBjeD0iOSIgY3k9IjkiIHI9IjgiIGZpbGw9IiNGRkYiLz48ZyBzdHJva2U9IiM5ODk4OTgiIHN0cm9rZS13aWR0aD0iMS45IiA+PHBhdGggZD0iTTQuNSA5aDkiLz48L2c+Cjwvc3ZnPg==")
      no-repeat center;
  }
  .rstcustom__rowContents {
    box-shadow: none;
    cursor: grab;
    border: 1px solid var(--ads-v2-color-bg-brand);
    border-radius: 4px;
    background: var(--ads-color-background-secondary);
  }
  .rstcustom__rowWrapper {
    padding: 10px 10px 10px 0;
    .rstcustom__rowLandingPad::before {
      border: 3px dashed var(--ads-v2-color-bg-brand);
      background: var(--ads-color-background-secondary);
      border-radius: 4px;
    }
    .rstcustom__rowCancelPad::before {
      border: 3px dashed #ff4d4f;
      background: #ffccc7;
    }
  }
  .rst__placeholder::before {
    z-index: 0;
  }
  .rst__lineHalfHorizontalRight::before,
  .rst__lineFullVertical::after,
  .rst__lineHalfVerticalTop::after,
  .rst__lineHalfVerticalBottom::after,
  .rstcustom__lineChildren::after {
    background-color: #c7c7c7;
  }
  .rstcustom__rowWrapper:hover {
    opacity: 1;
    .rstcustom__rowContents {
      background: #fff;
      box-shadow: 0 0 0 4px var(--ads-color-background-secondary);
    }
  }
  .icon-select-container {
    display: inline-block;
  }
  .rst__placeholder {
    max-width: unset;
    &::before {
      left: 20px;
      right: 20px;
    }
    &.rst__placeholderLandingPad::before {
      border: 3px dashed var(--ads-v2-color-bg-brand);
      background: var(--ads-color-background-secondary);
      border-radius: 4px;
    }
    &.rst__placeholderCancelPad::before {
      border: 3px dashed #ff4d4f;
      background: #ffccc7;
    }
  }
  .rstcustom__toolbarButton {
    margin-right: 5px;
  }
}

::selection {
  color: var(--ads-v2-color-fg-on-brand);
  background: var(--ads-v2-color-bg-brand);
}
