:root {
  /* orange */
  --appsmith-color-orange-900: #032121;
  --appsmith-color-orange-800: #064744;
  --appsmith-color-orange-700: #0f6e66;
  --appsmith-color-orange-600: #1c9486;
  --appsmith-color-orange-500: #2cbba6; /* Primary */
  --appsmith-color-orange-400: #50c7b1;
  --appsmith-color-orange-300: #77d4bf;
  --appsmith-color-orange-200: #a2e0d1;
  --appsmith-color-orange-100: #d1ede5;
  --appsmith-color-orange-50: #ebfaf5;

  /* black */
  --appsmith-color-black: #000;
  --appsmith-color-black-900: #191919;
  --appsmith-color-black-800: #393939;
  --appsmith-color-black-700: #575757;
  --appsmith-color-black-600: #6b6b6b;
  --appsmith-color-black-500: #939393;
  --appsmith-color-black-400: #b3b3b3;
  --appsmith-color-black-300: #d7d7d7;
  --appsmith-color-black-250: #e0dede;
  --appsmith-color-black-200: #e7e7e7;
  --appsmith-color-black-100: #f1f1f1;
  --appsmith-color-black-50: #f8f8f8;
  --appsmith-color-black-0: #ffffff;

  /* green */
  --appsmith-color-green-500: #03b364;
  --appsmith-color-green-50: #e5f6ec;

  /* yellow */
  --appsmith-color-yellow-500: #fec518;
  --appsmith-color-yellow-50: #fff8e2;

  /* red */
  --appsmith-color-red-500: #f13125;
  --appsmith-color-red-50: #ffeaec;
}
